<template>
  <div class="">

    <div class="container">
      <div class="row">
        <h3 class="wishlist_items">Your Wishlist Items</h3>
      </div>

      <!-- empty wishlist -->
      <div v-if="wishlist.contents.length < 1" class="empty_wishlist_container">
        <!-- empty cart image -->
        <div class="wishlist_img_container">
          <img src="../../public/images/Wishlist.svg" />
        </div>

        <!-- empty cart content -->
        <div class="wishlist_contain-container">
          <h3>Your wishlist is empty</h3>
          <article>
            <p>Seems like you don't have wishes here.</p>
            <p>Explore more and make a wish.</p>
            <p>Store everything you love on page</p>
          </article>
        </div>

        <!-- wishlist empty home button -->
        <router-link :to="{ name: 'Home' }">
          <button class="cart_checkout_btn">Go To Home</button>
        </router-link>
      </div>

      <!-- wishlist product list -->
      <div v-else class="wishlist_product_container">
        <!-- wishlist header -->
        <div class="wishlist_product_header">
          <h5>Product</h5>
          <h5>Price</h5>
          <!-- <h5>Stock Status</h5> -->
          <h5>Action</h5>
          <h5>Remove</h5>
        </div>

        <!-- wishlist product list -->
        <!-- START PRODUCT LOOP FROM HERE -->
        <div v-for="item in wishlist.contents" :key="item.id" class="wishlist_product_info">
          <div class="wishlist_image_name-container">
            <div class="wishlist_image-container">
              <img :src="img_thumbnail_url+item.product.thumbnail_img" />
            </div>

            <h4>{{ item.product.name }} </h4>
          </div>

          <div class="wishlist_price_container">
            <h4 class="wishlist_price">BDT {{ item.product.price }}</h4>
          </div>

          <!-- product buy or go to home -->
          <div class="wishlist_action_btn">
            <router-link  :to="{ name: 'product', params: { slug: item.product.slug } }" class="add_to_cart_btn">Add to cart</router-link>
          </div>

          <!-- delete button -->
          <button @click.prevent="itemRemove(item.id)" class="delete_btn">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="currentColor"
              class="delete_icon"
            >
              <path
                fill-rule="evenodd"
                d="M16.5 4.478v.227a48.816 48.816 0 013.878.512.75.75 0 11-.256 1.478l-.209-.035-1.005 13.07a3 3 0 01-2.991 2.77H8.084a3 3 0 01-2.991-2.77L4.087 6.66l-.209.035a.75.75 0 01-.256-1.478A48.567 48.567 0 017.5 4.705v-.227c0-1.564 1.213-2.9 2.816-2.951a52.662 52.662 0 013.369 0c1.603.051 2.815 1.387 2.815 2.951zm-6.136-1.452a51.196 51.196 0 013.273 0C14.39 3.05 15 3.684 15 4.478v.113a49.488 49.488 0 00-6 0v-.113c0-.794.609-1.428 1.364-1.452zm-.355 5.945a.75.75 0 10-1.5.058l.347 9a.75.75 0 101.499-.058l-.346-9zm5.48.058a.75.75 0 10-1.498-.058l-.347 9a.75.75 0 001.5.058l.345-9z"
                clip-rule="evenodd"
              />
            </svg>
          </button>
        </div>
      </div>
    </div>


  </div>
</template>
<script>
export default {
  mounted() {
    this.$store.dispatch("wishlistContent");
  },
  methods:{
    async itemRemove(id) {
      await this.axios
        .post("wishlist/item/remove", {
          headers: this.$apiHeader,
          id: id,
        })
        .then((resp) => {
       //   console.log(resp);
          if (resp.data.success == true) {
            this.$store.dispatch("wishlistContent");
            this.$toast.error(resp.data.message);
          }
        })
        .catch((error) => {
          this.$toast.error(error.response.data.message);
        });
    },
  },
  computed: {
    wishlist() {
      return this.$store.getters.wishlistContent;
    },
  },
};
</script>

<style scoped>
.null_wishlist_heading {
  margin-top: 40px;
}

.null_wishlist_heading img {
  height: 100px;
}

.instruction {
  text-align: left;
  margin-left: 40%;
  margin-top: 30px;
}

.instruction ul {
  margin-left: -20px;
}

.instruction ul li {
  list-style-type: disc !important;
}

.wishlist_stock_container {
  margin: 20px;
}
.wishlist_action_container {
  display: flex;
  margin-top: 20px;
}

.wishlist_cart_btn {
  width: 60%;
  height: 36px;
  color: #000;
  box-shadow: 0 1pt 12pt rgb(150 165 237);
  font-size: 14px;
  font-family: monospace;
}

.wishlist_close_btn {
  height: 36px;
  padding: 0px 10px;
  margin-left: 15px;
}

th {
  text-align: center !important;
}
.wishlist_product_container {
  display: flex;
}
.wishlist_product_container > img {
  width: 80px;
  height: 85px;
}
.wishlist_product_container > p {
  padding: 15px 10px;
  font-size: 14px;
}

.w_old_price {
  text-decoration: line-through;
  color: red;
  padding: 0px 10px;
}

@media screen and (max-width: 768px) {
  .wishlist_content_container {
    overflow-x: scroll !important;
  }

  .wishlist_product_container > img {
    width: 50px;
    height: 55px;
  }

  .wishlist_product_container > p {
    padding: 0px 5px;
    font-size: 12px;
  }

  .wishlist_action_container {
    margin-top: 8px;
  }

  .wishlist_cart_btn {
    width: 65%;
  }

  .instruction {
    margin-left: 12%;
  }
}
</style>
