<template>
  <div class="container mb-5">
    <!-- <loading :active.sync="isLoading" :can-cancel="true" :is-full-page="fullPage"></loading> -->
    <!-- Breadcrumb End-->
    <div class="row" v-if="cart.total > 0">
      <!--Middle Part Start-->
      <div id="content">
        <div class="row justify-content-center">
          <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="custom-box mt-3">
              <div class="checkout_header">
                <h4 style="text-align:center">Order Submit OR <a class="btn " href="/">Buy More </a></h4>

                <div
                  class="custom_margin_spin spinner-grow spinner-grow-sm text-success"
                  role="status"
                >
                  <span class="sr-only"></span>
                </div>
              </div>

              <form @submit.prevent="SubmitCheckout">
                <div class="form-group  mb-3">
                  <label class="form-label" for="name"
                    >Name <b class="text-danger">*</b></label
                  >
                  <input
                    type="text"
                    name="name"
                    class="form-control rounded"
                    v-model="form.name"
                    placeholder="your full name"
                    required
                  />
                </div>

                <div class="form-group  mb-3">
                  <label class="form-label" for="customer_phone"
                    >Mobile Number <b class="text-danger">*</b></label
                  >
                  <input
                    type="text"
                    name="customer_phone"
                    placeholder="01xxx-xxxxx"
                    class="form-control rounded"
                    maxlength="11"
                    minlength="11"
                    v-model="form.customer_phone"
                    required
                  />
                </div>

                <div class="form-group  mb-3">
                  <label class="form-label" for="address"
                    >Address <b class="text-danger">*</b></label
                  >
                  <textarea
                    name="address"
                    placeholder="Full Address"
                    class="form-control rounded"
                    @keyup="selectShippingCost"
                    v-model="form.address"
                    required
                  ></textarea>

                  <!-- this part is commented because if we want to keep both payment system than we can just uncomment it and start working with it -->
                  <!-- <div class="row">
                    <div class="col-lg-6">
                      <div
                        class="payment_option"
                        :class="{ payment_option_active: payment_option == 1 }"
                        @click="payment_option = 1"
                      >
                        <h4>Cash On Delivery</h4>
                      </div>
                    </div>
                    <div class="col-lg-6">
                      <div
                        :class="{ payment_option_active: payment_option == 2 }"
                        class="payment_option"
                        @click="payment_option = 2"
                      >
                        <h4>Online Payments</h4>
                      </div>
                    </div>
                  </div> -->



                  <div class="form-group text-center mt-3">
                    <button
                      class="btn btn-sm btn-block btn-primary  quick_cart_btn"
                      type="submit"
                      style="width: 170px;"
                      :disabled="form.busy"
                    >
                      <i class="fa fa-spinner fa-spin" v-if="form.busy"></i>
                      CONFIRM ORDER
                    </button>
                  </div>
                </div>
              </form>

              <form action="/pay" id="payment_form" method="post">
                <input type="hidden" name="order_id" id="__o_id" />
              </form>
            </div>
          </div>

          <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
            <div class="custom-box mt-3">
              <div class="cart_highlight">
                <h4 style="text-align:center; font-size:18px; margin-bottom:16px">ORDER SUMMARY</h4>
                <table class="table table-hover table-centered table-striped">
                  <thead>
                    <tr>
                      <th>PRODUCT</th>
                      <th>QUANTITY</th>
                      <th>AMOUNT</th>
                      <th>ACTION</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(item, cart_index) in cartContent.contents"
                      :key="cart_index"
                      class="checkout_table"
                    >
                      <td>
                        <div class="checkout_cart_product">
                          <img :src="img_thumbnail_url + item.product.thumbnail_img" />
                          <p>
                            {{ item.product.name.substring(0, 30) }}
                            <span v-if="item.product.name.length > 30"
                              >...</span
                            >
                          </p>
                        </div>
                      </td>
                      <td>
                        <ul style="text-decoration: none">
                          <li class="q-i-d" @click="updateQuantity(item, 1)">
                            <i
                              style="margin-bottom:5px;"
                              class="bi bi-arrow-up-circle"
                            ></i>
                          </li>
                          <li>
                            <span style="margin-left:2px;">
                              {{ item.qty }}
                            </span>
                          </li>
                          <li class="q-i-d" @click="updateQuantity(item, 0)">
                            <i class="bi bi-arrow-down-circle"></i>
                          </li>
                        </ul>
                      </td>
                      <td>
                        <p class="checkout_cart_subtotal">
                          &#2547;{{ item.product.price * item.qty }}
                        </p>
                      </td>
                      <td>
                        <h4
                          class="text-danger checkout_cart_remove"
                          @click="cartRemove(item.id)"
                        >
                          <button class="delete_btn">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 24 24"
                              fill="currentColor"
                              class="delete_icon"
                            >
                              <path
                                fill-rule="evenodd"
                                d="M16.5 4.478v.227a48.816 48.816 0 013.878.512.75.75 0 11-.256 1.478l-.209-.035-1.005 13.07a3 3 0 01-2.991 2.77H8.084a3 3 0 01-2.991-2.77L4.087 6.66l-.209.035a.75.75 0 01-.256-1.478A48.567 48.567 0 017.5 4.705v-.227c0-1.564 1.213-2.9 2.816-2.951a52.662 52.662 0 013.369 0c1.603.051 2.815 1.387 2.815 2.951zm-6.136-1.452a51.196 51.196 0 013.273 0C14.39 3.05 15 3.684 15 4.478v.113a49.488 49.488 0 00-6 0v-.113c0-.794.609-1.428 1.364-1.452zm-.355 5.945a.75.75 0 10-1.5.058l.347 9a.75.75 0 101.499-.058l-.346-9zm5.48.058a.75.75 0 10-1.498-.058l-.347 9a.75.75 0 001.5.058l.345-9z"
                                clip-rule="evenodd"
                              />
                            </svg>
                          </button>
                        </h4>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div class="cart-total">
                <table class="table table-hover  ">
                  <tbody>
                    <tr>
                      <td>Sub Total</td>
                      <td colspan="2"></td>
                      <td>
                        :
                        <span>&#2547;{{ form.total }}</span>
                      </td>
                    </tr>
                    <tr v-if="form.coupon_discount > 0">
                      <td>Coupon Discount</td>
                      <td colspan="2"></td>
                      <td>
                        :
                        <span>&#2547;{{ form.coupon_discount }}</span>
                      </td>
                    </tr>
                    <tr>
                      <td>Shipping Charge</td>
                      <td colspan="2"></td>
                      <td>
                        :
                        <span>&#2547;{{ form.shipping_cost }} </span>
                      </td>
                    </tr>
                    <tr>
                      <td>Payable Amount</td>
                      <td colspan="2"></td>
                      <td>
                        :
                        <span
                          >&#2547;{{
                            parseInt(form.total) +
                              parseInt(form.shipping_cost) -
                              parseInt(form.coupon_discount)
                          }}</span
                        >
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div class="coupon" style="margin-top:24px">
                  <div style="display:flex">
                    <input
                      id="coupon_code"
                      v-model="coupon_code"
                      type="text"
                      placeholder="apply if you have coupon"
                      class="form-control"
                    />
                    <button
                      :disabled="coupon_code.length <= 0"
                      class="btn btn-primary"
                      @click.prevent="applyCoupon"
                      style="border-radius:0px;"
                    >
                      Apply
                    </button>
                  </div>
                </div>
              </div>
            </div>

            
          </div>

          <div class="" style="display: flex;justify-content: center;">
            <div class="spiner_container">
              <div
                class="custom_margin_spin spinner-grow spinner-grow-sm text-success"
                role="status"
              >
                <span class="sr-only"></span>
              </div>
              <div>
                <a class="btn " href="/">Buy More Products </a>
              </div>
              <div
                class="custom_margin_spin spinner-grow spinner-grow-sm text-primary"
                role="status"
              >
                <span class="sr-only"></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row" v-else>
      <div class="empty-cart">
        <h4 class="text-uppercase">your cart is empty</h4>
        <img
          style="margin-bottom: 50px;
                margin-top: 50px;"
          src="https://software.mohasagor.com/public/storage//images/static/cartEmpty.jpg"
        />
        <a
          href="/"
          style="    background: #ff4d03;
                color: #fff;
                padding: 12px 15px;
                border-radius: 5px;"
          >Shopping More</a
        >
      </div>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import { Form } from "vform";
import ObjectToFD from "vue-object-to-formdata";
Vue.use(ObjectToFD);

// import Loading from "vue-loading-overlay";
// import  "vue-loading-overlay/dist/vue-loading.css";

export default {
  created() {
    window.scrollTo(0, 0);
    this.user();
    this.getCartContent();
    this.$store.dispatch("cartContent");
  },
  data() {
    return {
      form: new Form({
        customer_phone: "",
        name: "",
        address: "",
        city_id: 1,
        total: 0,
        discount: 0,
        cart: "",
        shipping_cost: 60,
        sub_city_id: 76,
        coupon_discount: 0,
        coupon_type: "",
        coupon_id: "",
        user_token: localStorage.getItem("user_token"),
      }),
      isLoading: true,
      fullPage: true,
      cities: null,
      cart: {
        total: 0,
      },
      disabled: true,
      sub_cities: null,
      city_name: null,
      sub_city_name: null,
      payment_option: 1,
      coupon_code: "",
      coupon: false,
    };
  },

  methods: {
    async SubmitCheckout() {
      if (this.form.customer_phone.substring(0, 1) != 0) {
        alert("invalid phone number");
        return;
      }

      // if(this.payment_option !=1 ||  this.payment_option !=2){
      //    alert('Please Select A Payment Method');
      //   return;
      // }
      await this.form
        .post("checkout/order", this.$objectToFD(this.form))
        .then((resp) => {
          if (resp.data.success == true) {
            this.$router.push({
              name: "order_success",
              query: {
                order_id: resp.data.order_id,
                order_invoice: resp.data.order_invoice,
                order_amount: resp.data.order_amount,
              },
            });
          } else {
            this.$toast.error(resp.data.message);
          }
        })
        .catch((error) => {
          this.$toast.error(error.response.data.message);
        });
    },

    selectShippingCost() {
      let small_case = this.form.address.includes("dhaka");
      let large_case = this.form.address.includes("Dhaka");
      let bangla_case = this.form.address.includes("ঢাকা");
      if (small_case === true || large_case === true || bangla_case === true) {
        this.form.shipping_cost = 60;
      } else {
        this.form.shipping_cost = 120;
      }
    },

    async citySearch() {
      if (this.city_name.length > 0) {
        await this.axios
          .get("get/city/search/" + this.city_name)
          .then((resp) => {
            //   console.log(resp);
            if (resp.data.cities.length > 0) {
              document.getElementById("city_name_container").style.display =
                "block";
              this.cities = resp.data.cities;
            } else {
              this.cities = null;
            }
          });
      }
    },

    //select city
    selectCity(city) {
      document.getElementById("city_name_container").style.display = "none";
      this.city_name = city.name;
      this.form.city_id = city.id;
      this.form.shipping_cost = city.delivery_charge;
    },

    async subCitySearch() {
      if (this.form.city_id.length < 1) {
        this.$toast.error("firstly,enter your district name");
        this.sub_city_name = "";
        return;
      }
      if (this.sub_city_name.length > 0) {
        await this.axios
          .get(
            "get/sub_city/search/" +
              this.form.city_id +
              "/" +
              this.sub_city_name
          )
          .then((resp) => {
            //   console.log(resp);
            if (resp.data.sub_cities.length > 0) {
              document.getElementById("sub_city_name_container").style.display =
                "block";
              this.sub_cities = resp.data.sub_cities;
            } else {
              this.sub_cities = null;
            }
          });
      }
    },

    //select city
    selectSubCity(city) {
      document.getElementById("sub_city_name_container").style.display = "none";
      this.sub_city_name = city.name;
      this.form.sub_city_id = city.id;
    },

    user() {
      this.axios
        .get("/userToCheck", {
          params: {
            user_token: localStorage.getItem("user_token"),
          },
        })
        .then((resp) => {
          if (resp.data.status == "AUTHORIZED") {
            this.form.customer_phone = resp.data.user.customer_phone;
            this.form.name = resp.data.user.name;
            this.form.address = resp.data.user.address;
          }
        });
    },

    getCartContent() {
      this.axios.get("/get/cart/content").then((resp) => {
        // console.log(resp.data)
        this.cart.total = resp.data.total;
        this.form.total = resp.data.total;
      });
    },

    makePaymentSSl(order) {
      let button = document.getElementById("sslczPayBtn");
      let customer = order.customer;
      var obj = {};
      obj.cus_name = customer.name;
      obj.cus_phone = order.customer_phone;
      obj.cus_email = customer.email ? customer.email : "abc@email.com";
      obj.cus_addr1 = customer.address;
      obj.amount =
        parseInt(order.total) -
        (parseInt(order.discount) + parseInt(order.shipping_cost));
      obj.order_id = order.id;
      button.setAttribute("postdata", obj);
      button.click();
      document.getElementById("__o_id").value = order.id;
      document.getElementById("payment_form").submit();
    },

    async applyCoupon() {
      if (this.coupon_code.length <= 0) {
        this.$toast.error("Coupon Code Is Empty");
        document.getElementById("coupon_code").focus();
        return;
      }

      await this.axios
        .post("apply/coupon/code", {
          coupon_code: this.coupon_code,
        })
        .then((resp) => {
          if (resp.data.success == true) {
            //  console.log(resp);
            let discount = 0;
            let coupon = resp.data.coupon;
            let total = this.form.total;
            if (coupon.discount_type == "percentage") {
              discount =
                (parseInt(total) / parseInt(100)) *
                parseInt(coupon.discount_amount);
            } else {
              discount = parseInt(coupon.discount_amount);
            }
            this.form.coupon_discount = discount.toFixed(0);
            this.form.coupon_id = coupon.id;
            this.$toast.success(resp.data.message);
            this.coupon_code = "";
          } else {
            this.$toast.error(resp.data);
          }
        })
        .catch((error) => {
          this.$toast.error(error.response.data.message);
        });
    },

    async updateQuantity(item, type) {
      let quantity = 0;
      if (type == 1) {
        quantity = parseInt(item.qty) + parseInt(1);
      } else {
        quantity = parseInt(item.qty) - parseInt(1);
      }
      if (parseInt(quantity) <= 0) {
        this.$toast.warning("Quantity should be at least one");
        quantity = 1;
        return;
      }

      await this.axios
        .post("cart/item/update", {
          header: this.$apiHeader,
          qty: quantity,
          id: item.id,
        })
        .then((resp) => {
          if (resp.data.success == true) {
            this.$toast.success(resp.data.message);
            this.$store.dispatch("cartContent");
          }
        })
        .catch((error) => {
          this.$toast.error(error.response.data.message);
        });
    },

    async cartRemove(id) {
      await this.axios
        .post("cart/item/remove", {
          headers: this.$apiHeader,
          id: id,
        })
        .then((resp) => {
          //  console.log(resp);
          if (resp.data.success == true) {
            this.$store.dispatch("cartContent");
            this.getCartContent();
            this.$toast.success(resp.data.message);
          }
        })
        .catch((error) => {
          this.$toast.error(error.response.data.message);
        });
    },
  },
  computed: {
    cartContent() {
      return this.$store.getters.cartContent;
    },
  },
};
</script>

<style scoped>
.checkout_header {
  display: flex;
  background: #fff;
  width: 100%;
  height: 60px;
  padding: 2% 18%;
  border-radius: 15px;
  margin-bottom: 10px;
}

.checkout_header h4 {
  font-size: 22px;
  font-family: monospace;
  font-weight: bold;
}
.checkout_header > h4 > a {
  font-weight: bold;
  font-size: 22px;
  color: rgb(41, 41, 221);
}

label {
  font-weight: 700;
  font-family: system-ui;
}

.spiner_container {
  display: flex;
  background: #fff;
  width: 310px;
  height: 60px;
  padding: 12px 25px;
  box-shadow: 0 1pt 12pt rgb(150 165 237);
  border-radius: 15px;
  margin-top: 20px;
  justify-content: center;
}

.spiner_container a {
  font-size: 18px;
  font-family: monospace;
  font-weight: bold;
}

.custom_margin_spin {
  margin: 12px 0px;
}

.order_box_header {
  color: #000;
  margin: 1rem auto;
  height: 50px;
  border-radius: 5px;
  position: relative;
  display: block;
  box-shadow: 0 0 10px -5px #000000;
  padding: 0px 10px;
  transition: 1s;
}

.order_box_header h4 {
  padding: 10px 60px;
}

.checkout_cart_product img {
  width: 60px;
  height: 60px;
}

.checkout_cart_remove {
  padding: 10px 25px;
  cursor: pointer;
}
@media only screen and (max-width:768px) {
  .checkout_cart_remove {
    padding: 16px 25px;

  }
}

.q-i-d {
  cursor: pointer;
}

.checkout_cart_subtotal {
  padding: 20px 5px;
}

input {
  height: 44px !important;
}

.empty-cart {
  width: 50%;
  background: #fff;
  text-align: center;
  margin-left: 25%;
  padding: 50px 50px;
  box-shadow: 3px 3px 3px #ddd;
}
.payment_option {
  background: #e6eaf2;
  padding: 15px 15px;
  text-align: center;
  font-size: 13px;
  border-radius: 5px;
  cursor: pointer;
  height: 70px;
  margin-top: 8px;
}
.payment_option h4 {
  font-size: 16px;
  font-weight: 600;
}
.payment_option_active {
  border: 1px solid #ff4d03;
}

.order_place_btn {
  height: 45px;
  width: 170px;
  font-size: 16px;
  font-weight: 500;
}

@media screen and (max-width: 768px) {
  .checkout_header {
    padding: 4% 5%;
    margin-bottom: 10px;
  }

  .checkout_header h4 {
    font-size: 19px;
  }

  .checkout_header > h4 > a {
    font-size: 17px;
  }

  .spiner_container {
    margin-left: 5%;
  }

  .cart_highlight {
    overflow-x: auto;
  }

  .custom-box {
    width: 97%;
    height: auto !important;
    background: #fff !important;
    padding: 10px 5px !important;
    margin: auto;
    border-radius: 10px !important;
    box-shadow: 0 0pt 1pt rgb(150 165 237) !important;
    margin-left: 0%;
  }
}
</style>
